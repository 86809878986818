/* eslint-disable */
import { defineStore } from 'pinia';
import { useLanguagesStore } from './languages';

export const useMenuStore = defineStore('menus', {
	state: () => ({
		footer: [],
		header: [],
		static_pages_menu: [],
		footer_descriptions: [],
		mobile_navigation_menu_sports: [],
		mobile_navigation_menu_casino: [],
		landing_page_menu: [],
		isCasinoActive: undefined,
		isLiveCasinoActive: undefined,
		isSportsActive: undefined,
		isLiveSportsActive: undefined,
		isRacingSportsActive: undefined
	}),
	getters: {
		getTopCompetitions(state) {
			return state.top_competitions;
		},
		getFooterMenus(state) {
			return state.footer;
		},
		getHeaderMenus(state) {
			return state.header;
		},
		getStaticPageMenu(state) {
			return state.static_pages_menu;
		},
		getLandingPageMenu(state) {
			return state.landing_page_menu;
		},
		getMobileNavigationMenuSports(state) {
			return state.mobile_navigation_menu_sports.children;
		},
		getMobileNavigationMenuCasino(state) {
			return state.mobile_navigation_menu_casino.children;
		},
		isCasinoActive(state) {
			if (state.header.some(header_menu_item => header_menu_item.click_url === '/casino') ||
				state.mobile_navigation_menu_casino.children.some(mobile_menu_item => mobile_menu_item.click_url === '/casino')) {
					return true
			} else return false
		},
		isLiveCasinoActive(state) {
			if (state.header.some(header_menu_item => header_menu_item.click_url === '/live-casino') ||
				state.mobile_navigation_menu_casino.children.some(mobile_menu_item => mobile_menu_item.click_url === '/live-casino')) {
					return true
			} else return false
		},
		isSportsActive(state) {
			if (state.header.some(header_menu_item => header_menu_item.click_url === '/pre-sports') ||
				state.mobile_navigation_menu_sports.children.some(mobile_menu_item => mobile_menu_item.click_url === '/pre-sports')) {
					return true
			} else return false
		},
		isLiveSportsActive(state) {
			if (state.header.some(header_menu_item => header_menu_item.click_url === '/live-sports') ||
				state.mobile_navigation_menu_sports.children.some(mobile_menu_item => mobile_menu_item.click_url === '/live-sports')) {
					return true
			} else return false
		},
		isRacingSportsActive(state) {
				if (state.header.some(header_menu_item => header_menu_item.click_url === '/racing') ||
					state.mobile_navigation_menu_sports.children.some(mobile_menu_item => mobile_menu_item.click_url === '/racing')) {
						return true
				} else return false
		},
		isESportsActive(state) {
			if (state.header.some(header_menu_item => header_menu_item.click_url === '/esports') ||
				state.mobile_navigation_menu_sports.children.some(mobile_menu_item => mobile_menu_item.click_url === '/esports')) {
					return true
			} else return false
		},
		getFooterDescription(state) {
			// Get selected locale
			const locale = useLanguagesStore().selectedLanguage.locale ?? 'en';

			// Find description by locale
			let footer_description = _.find(state.footer_descriptions, { 'locale': locale });

			// If description not found, use english
			if (!footer_description) footer_description = _.find(state.footer_descriptions, { 'locale': 'en' });

			// If description still not found, return
			if (!footer_description) return;

			// Return description
			return footer_description.description;
		},
		isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				// true for mobile device
				return true;
			}

			return false;
		}
	},
	actions: {
		async fetchMenus(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/menus/'+ payload, { headers: {'Version' : 'v2'} }).then((response) => {
				this.footer = response.data.footer_menu;
				this.header = response.data.header_menu;
				this.static_pages_menu = response.data.static_pages_menu;
				this.mobile_navigation_menu_sports = response.data.mobile_navigation_menu[0]
				this.mobile_navigation_menu_casino = response.data.mobile_navigation_menu[1]
				this.landing_page_menu = response.data.landing_page_menu;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setFooterDescriptions(data) {
			this.footer_descriptions = data;
		}
	},
})